<template>
  <dx-load-panel :show-indicator="true" :show-pane="true"
      :shading="true" :hide-on-outside-click="false" shading-color="rgba(0,0,0,0.4)" />
</template>

<script>
import { DxLoadPanel } from 'devextreme-vue/load-panel'

export default {
  components: {
    DxLoadPanel
  }
}
</script>