<template>
  <div>
    <h2 class="content-block">Home</h2>
    <div>
      <div class="dx-card responsive-paddings">
        <DxPieChart id="pie" :data-source="grafik1" palette="Bright" title="Per Supplier" :export="{ enabled: true }"
          :series="series1" :legend="legend">
        </DxPieChart>
        <DxPieChart id="pie2" :data-source="grafik2" palette="Bright" title="Per Kategori" :export="{ enabled: true }"
          :series="series2" :legend="legend">
        </DxPieChart>
      </div>
    </div>
  </div>
</template>

<script>
import DxPieChart from 'devextreme-vue/pie-chart';
import Logic from '@/Logic';

export default {
  components: {
    DxPieChart
  },
  data() {
    return {
      legend: {
        rowCount: 3,
        verticalAlignment: 'bottom',
        horizontalAlignment: 'center',
        itemTextPosition: 'right'
      },
      grafik1: [],
      series1: {
        argumentField: 'supplier',
        valueField: 'jumlah',
        label: {
          visible: true,
          customizeText(e) {
            return Logic.formatNumber(e.percent * 100, '#.#') + '%'
          }
        }
      },
      grafik2: [],
      series2: {
        argumentField: 'kategori',
        valueField: 'jumlah',
        label: {
          visible: true,
          customizeText(e) {
            return Logic.formatNumber(e.percent * 100, '#.#') + '%'
          }
        }
      }
    }
  },
  methods: {
    refresh() {
      let id = 1
      this.loadingVisible = true
      Logic.postRequest('dashboard/daftar', { id })
        .then(res => {
          try {
            let data = JSON.parse(res.data)
            data = data.data
            this.grafik1 = data.grafik1
            this.grafik2 = data.grafik2
          } catch (error) {
            // Logic.alert(error)
          }
        })
        .catch(e => {
          Logic.alert(e)
        })
        .then(() => this.loadingVisible = false)
    },
  },
  mounted() {
    this.refresh()
  }
}
</script>