<template>
  <div>
    <my-load-panel v-model:visible="loadingVisible" />
    <dx-popup ref="dialogRef" v-model:visible="dialogVisible" :title="dialogTitle" :hide-on-outside-click="false"
      :show-close-button="true">
      <dx-toolbar-item widget="dxButton" location="after" toolbar="bottom" :options="batalOptions" />
      <dx-toolbar-item widget="dxButton" location="after" toolbar="bottom" :options="simpanOptions" />
      <dx-form ref="formRef" v-model:formData="formData" :col-count="2">
        <dx-simple-item data-field="nama">
          <dx-label text="Nama" />
          <dx-required-rule message="Nama harus diisi" />
          <dx-string-length-rule :max="255" message="Panjang maksimal 255 karakter" />
        </dx-simple-item>
        <dx-simple-item data-field="token">
          <dx-label text="Token" />
          <dx-required-rule message="Token harus diisi" />
          <dx-string-length-rule :max="50" message="Panjang maksimal 50 karakter" />
        </dx-simple-item>
        <dx-simple-item data-field="is_aktif" editor-type="dxCheckBox" :editorOptions="{ text: 'Is Aktif' }"
          :label="{ visible: false }" />
        <dx-empty-item />
        <dx-empty-item />
      </dx-form>
    </dx-popup>
    <h2 class="content-block">{{ title }}</h2>
    <div>
      <div class="dx-card responsive-paddings">
        <dx-data-grid ref="gridRef" :data-source="dataSource" :remote-operations="true" :allow-column-reordering="true"
          :allow-column-resizing="true" column-resizing-mode="widget" :column-min-width="120">
          <dx-state-storing :enabled="true" type="localStorage" :storage-key="gridKey" />
          <dx-paging :enabled="true" :page-size="20" />
          <dx-pager :visible="true" :allowed-page-sizes="[20, 50, 100, 500]" display-mode="full"
            :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true" />
          <dx-toolbar>
            <dx-item location="before">
              <simple-button icon="add" @click="onBaru" />
            </dx-item>
            <dx-item name="exportButton" />
            <dx-item name="columnChooserButton" />
            <dx-item location="after">
              <simple-button icon="refresh" @click="refresh" />
            </dx-item>
          </dx-toolbar>
          <dx-filter-row :visible="true" />
          <dx-column-chooser :enabled="true" />
          <dx-export :enabled="true" file-name="data-export.xlsx" />
          <dx-column type="buttons" :fixed="false" fixed-position="left">
            <dx-button icon="edit" @click="onEdit" />
            <dx-button icon="trash" @click="onHapus" />
          </dx-column>
          <dx-column data-field="id" caption="id" data-type="number" />
          <dx-column data-field="nama" caption="nama" data-type="string" />
          <dx-column data-field="token" caption="Token" data-type="string" />
          <dx-column data-field="is_aktif" caption="Is Aktif" data-type="boolean" />
          <dx-column data-field="user_add" caption="User Add" data-type="string" />
          <dx-column data-field="user_update" caption="User Update" data-type="string" />
          <dx-column data-field="created_at" caption="Created" data-type="date" format="yyyy-MM-dd HH:mm:ss" />
          <dx-column data-field="updated_at" caption="Updated" data-type="date" format="yyyy-MM-dd HH:mm:ss" />

        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'
import MyLoadPanel from '@/components/my-load-panel.vue'
import DxForm, { DxSimpleItem, DxLabel, DxRequiredRule, DxStringLengthRule, DxEmptyItem } from 'devextreme-vue/form'
import { DxDataGrid, DxColumn, DxFilterRow, DxColumnChooser, DxExport, DxToolbar, DxItem, DxButton, DxPaging, DxPager, DxStateStoring } from 'devextreme-vue/data-grid'
import { DxButton as SimpleButton } from 'devextreme-vue/button'
import Logic from '@/Logic'
import { ref } from 'vue'

export default {
  setup() {
    const title = ref('Daftar Api Token')
    const dialogTitle = ref('Api Token')
    const gridKey = ref('api_token.grid')
    const dataSource = Logic.createDataSource('api_token/daftar')

    const loadingVisible = ref(false)
    const dialogVisible = ref(false)
    const gridRef = ref(null)
    const formRef = ref(null)
    const dialogRef = ref(null)
    const formData = ref({
      is_aktif: true
    })
    const simpanOptions = ref({
      text: 'Simpan',
      icon: 'save',
      type: 'success',
      onClick() {
        let form = Logic.getInstance(formRef)
        let dialog = Logic.getInstance(dialogRef)
        if (!form.validate().isValid) {
          return
        }
        loadingVisible.value = true
        let fd = form.option('formData')
        Logic.postRequest('api_token/' + (fd.id ? 'update' : 'baru'), fd)
          .then(() => {
            dialog.hide()
            Logic.notify('data telah disimpan')
            let grid = Logic.getInstance(gridRef)
            grid.refresh()
          })
          .catch(e => Logic.alert(e))
          .then(() => loadingVisible.value = false)
      }
    })

    const batalOptions = ref({
      text: 'Batal',
      icon: 'chevrondoubleleft',
      type: 'danger',
      onClick() {
        let dialog = Logic.getInstance(dialogRef)
        dialog.hide()
      }
    })

    return {
      simpanOptions, batalOptions,
      loadingVisible,
      title,
      dialogTitle, dialogVisible,
      dataSource,
      gridKey,
      gridRef, formRef, dialogRef,
      formData,
      refresh() {
        let grid = Logic.getInstance(gridRef)
        grid.refresh()
      },
      onBaru() {
        let dialog = Logic.getInstance(dialogRef)
        let form = Logic.getInstance(formRef)
        form.option('formData', {
          token: Logic.uuid().substring(0, 8),
          is_aktif: true
        })
        dialog.show()
      },
      onEdit(e) {
        loadingVisible.value = true
        let itemData = e.row.data
        let dialog = Logic.getInstance(dialogRef)
        let form = Logic.getInstance(formRef)
        Logic.postRequest('api_token/daftar', { id: itemData.id })
          .then(data => {
            data.password = ''
            data.password_confirmation = ''
            form.option('formData', data)
            dialog.show()
          })
          .catch(e => Logic.alert(e))
          .then(() => loadingVisible.value = false)
      },
      onHapus(e) {
        let itemData = e.row.data
        Logic.konfir()
          .then(b => {
            if (b) {
              loadingVisible.value = true
              Logic.postRequest('api_token/hapus', { id: itemData.id })
                .then(() => {
                  Logic.getInstance(gridRef).refresh()
                })
                .catch(e => Logic.alert(e))
                .then(() => loadingVisible.value = false)
            }
          })
      },
      getPassword() {
        let form = Logic.getInstance(formRef)
        let fd = form.option('formData')
        return fd.password
      },
    }
  },
  data() {
    return {
      currentId: null,
      imageUrl: ''
    }
  },
  methods: {

  },
  components: {
    MyLoadPanel,
    DxPopup, DxToolbarItem,
    DxLabel, DxRequiredRule, DxStringLengthRule,
    DxForm, DxSimpleItem, DxEmptyItem,
    DxDataGrid, DxColumn, DxFilterRow, DxColumnChooser, DxExport, DxToolbar, DxItem, DxButton, DxPaging, DxPager, DxStateStoring,
    SimpleButton
  }
}
</script>
