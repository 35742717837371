import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";
import App from "./App";
import appInfo from "./app-info";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faRefresh, faPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faRefresh, faPlus, faCheckCircle)

themes.initialized(() => {
    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.component('font-awesome-icon', FontAwesomeIcon)
    app.mount('#app');
});
