import { DateTime } from "luxon";
import Logic from "./Logic";

// const defaultUser = {
//   email: 'sandra@example.com',
//   avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
// };

export default {
  _user: null,
  loggedIn() {
    return !!this._user;
  },

  restoreLogin() {
    let s = localStorage.getItem('user')
    if (!s) {
      return
    }
    let user = null
    try {
      user = JSON.parse(s)
      let s_expired = user.expired
      let expired = DateTime.fromISO(s_expired)
      let d = DateTime.now()
      if (d > expired) {
        throw 'user token expired'
      }
      this._user = user
      return true
    } catch (error) {
      console.error(error)
    }
    return false
  },
  async logIn(email, password) {
    try {
      // Send request
      let user = await Logic.postRequest('login', { email, password })
      // console.log(email, password);
      if (user) {
        this._user = { ...user, email };
        localStorage.setItem('user', JSON.stringify(this._user))
      } else {
        throw 'invalid login'
      }
      return {
        isOk: true,
        message: 'Sukses'
      }
    }
    catch {
      return {
        isOk: false,
        message: 'Invalid login'
      }
    }
  },
  async logOut() {
    try {
      await Logic.postRequest('logout')
    } catch (error) {
      Logic.notify(error)
    }
    this._user = null;
    localStorage.clear();
  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
