export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Laporan Biaya",
    path: "/lap_biaya",
    icon: "like"
  },
  {
    text: "Laporan Laba Bersih",
    path: "/lap_laba_bersih",
    icon: "like"
  },
  {
    text: "Laporan Retur Pembelian",
    path: "/lap_retur_beli",
    icon: "like"
  },
  {
    text: "Laporan Stok dan Mutasi",
    path: "/lap_stok_mutasi",
    icon: "like"
  },
  {
    text: "Laporan Rekap Cara Bayar",
    path: "/lap_rekap_cara_bayar",
    icon: "like"
  },
  {
    text: "Pengguna",
    path: "/pengguna",
    icon: "user"
  },
  {
    text: "Api Token",
    path: "/api_token",
    icon: "lock"
  },
];
