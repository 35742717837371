import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { confirm, alert } from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'
import auth from './auth'
import Guid from 'devextreme/core/guid'
import { DateTime } from 'luxon'
import { formatNumber } from 'devextreme/localization';

// const base_url = 'http://127.0.0.1:8000/api/admin/';
const base_url = 'https://gallery.gpos-app.online/api/admin/';

class Logic {
  static formatNumber(n, f='#,###.##') {
    return formatNumber(n, f)
  }
  
  static parseDate(s, f = 'yyyy-MM-dd HH:mm:ss') {
    let t = DateTime.fromFormat(s, f)
    return t.toJSDate()
  }

  static formatDate(d, f = 'yyyy-MM-dd HH:mm:ss') {
    let t = DateTime.fromJSDate(d)
    let s = t.toFormat(f)
    return s
  }

  static formatDateWithoutTime(d) {
    return Logic.formatDate(d, 'yyyy-MM-dd')
  }


  static uuid() {
    let g = new Guid()
    let s = g.toString()

    return s.replace(/-/g, '')
  }

  static getLogoUrl() {
    return base_url + 'setting_landing/logo'
  }

  static getFotoBannerUrl(id) {
    return base_url + 'banner/foto/' + id
  }

  static getFotoTokoUrl(id) {
    return base_url + 'toko/foto/' + id
  }

  static getFotoPenggunaUrl(id) {
    return base_url + 'pengguna/foto/' + id
  }

  static createCustomStoreConfig(relative_path) {
    return {
      key: 'id',
      load(loadOptions) {
        return Logic.postRequest(relative_path, loadOptions)
      },
      byKey(key) {
        return Logic.postRequest(relative_path, { id: key })
      }
    }
  }

  static prosesValidationError(ar) {
    let ret = ''
    for (var x in ar) {
      for (var xx of ar[x]) {
        ret += xx + '\n'
      }
    }
    return ret
  }

  static async postRequest(relative_path, data, opt) {
    let options = {
      headers: {

      }
    }
    Object.assign(options, opt)
    options.headers.token = auth._user?.token
    return new Promise((resolve, reject) => {
      axios.post(base_url + relative_path, data, options)
        .then(res => {
          let api = res.data
          if (api.ok) {
            resolve(api.data)
          } else {
            reject(Logic.prosesValidationError(api.data))
          }
        })
        .catch(e => reject(e))
    })
  }

  static async uploadFile(relative_path, data) {
    return Logic.postRequest(relative_path, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  }

  static async notify(message, type = 'success') {
    return notify(message, type)
  }

  static async alert(html, title = 'Informasi') {
    return alert(html, title)
  }

  static async konfir(html = '<i>Apakah Anda yakin?</i>', title = 'Konfirmasi') {
    return confirm(html, title)
  }

  static createCustomStore(relative_path) {
    return new CustomStore(Logic.createCustomStoreConfig(relative_path))
  }

  static createDataSource(relative_path) {
    return new DataSource({
      store: Logic.createCustomStore(relative_path),
      paginate: true,
      pageSize: 20
    })
  }

  static getInstance(ref) {
    return ref.value.instance
  }

}
export default Logic;